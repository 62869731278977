import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import styled from 'styled-components';

import Block from '../../components/block/Block';

const DateCell = styled.td`
  width: 130px;
  vertical-align: top;
  color: var(--article-datetime);
`;

const TableRow = styled.tr`
  font-family: 'HelveticaNeue-Light', 'Open Sans', sans serif;
`;

const Resume = ({ data, intl: { formatMessage } }) => (
  <Block
    title={formatMessage({ id: 'menu@resume' })}
  >
    <table style={{ width: '100%' }}>
      <tbody>
        {
          // eslint-disable-next-line react/prop-types
          data && data.map(({ node }) => (
            <TableRow key={node.id}>
              <DateCell>{node.date}</DateCell>
              <td>{node.description}</td>
            </TableRow>
          ))
        }
      </tbody>
    </table>
  </Block>
);

Resume.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
    formatMessage: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape({
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    fr: PropTypes.shape({
      edges: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default injectIntl(Resume);
